export default {
  header_title: 'Community',
  extended_tabs: {
    topics: 'Topics',
    my_posts: 'My Posts',
  },
  new_reply_banner: {
    title_singal: 'New reply',
    title_plural: 'New replies',
    mark_read: 'Mark as read',
    hide_reply: 'Hide reply',
    hide_replies: 'Hide replies',
    mark_read_title: 'Marked as read',
    mark_read_description: 'Find your community replies anytime in <strong>Community</strong> > <strong>My Posts</strong>.',
    hide_reply_title: 'Replies hidden',
    hide_reply_description: 'You won\'t see reply notifications on your homepage unless you change this setting in your <strong>Profile</strong>. Find your community replies anytime in <strong>Community</strong> > <strong>My Posts</strong>.',
  },
};
