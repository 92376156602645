var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "25",
        height: "25",
        viewBox: "0 0 25 25",
        fill: "none"
      }
    },
    [
      _c(
        "mask",
        {
          staticStyle: { "mask-type": "alpha" },
          attrs: {
            id: "mask0_4600_23974",
            maskUnits: "userSpaceOnUse",
            x: "0",
            y: "0",
            width: "25",
            height: "25"
          }
        },
        [
          _c("rect", {
            attrs: {
              x: "0.207031",
              y: "0.273438",
              width: "24",
              height: "24",
              fill: "#D9D9D9"
            }
          })
        ]
      ),
      _c("g", { attrs: { mask: "url(#mask0_4600_23974)" } }, [
        _c("path", {
          attrs: {
            d:
              "M12.207 1.27344L21.257 6.67344C21.557 6.85677 21.7904 7.10677 21.957 7.42344C22.1237 7.7401 22.207 8.07344 22.207 8.42344V19.2734C22.207 19.8234 22.0112 20.2943 21.6195 20.6859C21.2279 21.0776 20.757 21.2734 20.207 21.2734H4.20703C3.65703 21.2734 3.1862 21.0776 2.79453 20.6859C2.40286 20.2943 2.20703 19.8234 2.20703 19.2734V8.42344C2.20703 8.07344 2.29036 7.7401 2.45703 7.42344C2.6237 7.10677 2.85703 6.85677 3.15703 6.67344L12.207 1.27344ZM12.207 12.9234L20.007 8.27344L12.207 3.62344L4.40703 8.27344L12.207 12.9234ZM12.207 15.2734L4.20703 10.4734V19.2734H20.207V10.4734L12.207 15.2734Z",
            fill: "#3A5981"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }