export default {
  header_title: 'Communauté',
  extended_tabs: {
    topics: 'Fils de discussion',
    my_posts: 'Mes commentaires',
  },
  new_reply_banner: {
    title_singal: 'Nouvelle réponse',
    title_plural: 'Nouvelles réponses',
    mark_read: 'Marquer comme lu',
    hide_reply: 'Masquer la réponse',
    hide_replies: 'Masquer les réponses',
    mark_read_title: 'Marqué comme lu',
    mark_read_description: 'Trouvez les réponses de votre communauté à tout moment dans <strong>Communauté</strong> > <strong>Mes Commentaires</strong>.',
    hide_reply_title: 'Réponses masquées',
    hide_reply_description: 'Vous ne verrez pas les notifications de réponse sur votre page d\'accueil, sauf si vous modifiez ce <strong>paramètre</strong> <strong>profil</strong>. Trouvez les réponses de votre communauté à tout moment dans la <strong>Communauté</strong> > <strong>Mes Commentaires</strong>.',
  },
};
