export default {
  header_title: 'Comunidad',
  extended_tabs: {
    topics: 'Temas de discusión',
    my_posts: 'Mis comentarios',
  },
  new_reply_banner: {
    title_singal: 'Nueva respuesta',
    title_plural: 'Nuevas respuestas',
    mark_read: 'Marcar como leído',
    hide_reply: 'Ocultar respuesta',
    hide_replies: 'Ocultar respuestas',
    mark_read_title: 'Marcado como leído',
    mark_read_description: 'Encuentra las respuestas de tu comunidad en cualquier momento en <strong>Comunidad</strong> > <strong>Mis comentarios</strong>.',
    hide_reply_title: 'Replies hidden',
    hide_reply_description: 'No verás notificaciones de respuesta en tu página de inicio a menos que cambies esta configuración en tu <strong>Perfil</strong>. Encuentre las respuestas de su comunidad en cualquier momento en <strong>Comunidad</strong> > <strong>Mis comentarios</strong>.',
  },
};
