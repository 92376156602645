export default {
  header_title: 'Entraînement',
  recommended: {
    title: 'Recommandation :',
    subtitle: 'En fonction de votre bilan de santé actuel, voici les modules que nous vous recommandons :',
  },
  modules: {
    title: 'Modules',
    sessions: 'Séance d\'Entraînement',
    button_explore: 'Explorez',
    button_start_session: 'Démarrer la séance',
  },
  discover: {
    title: 'Découvrir :',
  },
  completed: {
    title: 'Terminé :',
    subtitle: 'Passez en revue les modules que vous avez terminés pour rafraîchir vos compétences.',
  },
  first_checkup: {
    text: 'Effectuez un <strong>bilan de santé</strong> pour générer votre recommandation.',
    button: 'Faites un bilan',
  },
  buttons: {
    more: 'Voir plus',
    less: 'Voir moins',
  },
  empty: {
    title: '',
    subtitle: '',
  },
  element: {
    reports: {
      download_button: 'Télécharger les données',
    },
    popup: {
      tell_more_button: 'En savoir plus',
      download_button: 'Télécharger le PDF',
    },
    goal: {
      title: 'Objectif fixé',
      subtitle: 'Bravo pour avoir créé votre objectif! Travaillons les prochains jours pour le mener à bien. <br><br> Voici votre carte d\'objectifs. Vous pouvez la trouver dans l\'historique de vos objectifs.',
    },
    mood: {
      title: 'Humeur suivie',
      subtitle: 'Voici votre carte des humeurs! Vous pouvez la trouver dans votre historique des humeurs.',
    },
    thought: {
      title: 'Pensée équilibrée',
      subtitle: 'Bravo pour avoir équilibré votre pensée. Penser de façon différente n\'est pas toujours facile.<br><br>Voici votre carte des pensées! Vous pouvez la trouver dans votre historique équilibreur des pensées.',
    },
    cu_scheduler: {
      label: 'Effectuer mon prochain bilan dans',
      choices: {
        two_weeks: '2 semaines',
        four_weeks: '4 semaines',
        six_weeks: '6 semaines',
        eight_weeks: '8 semaines',
      },
    },
    webinar_registration: {
      register_button: 'Cliquez pour s\'inscrire',
      unregister_button: 'Inscription réussie!',
      success_message: 'Consultez vos courriels pour la confirmation.',
      join_now_button: 'En direct, cliquez pour participer!',
      removed_message: 'Inscription supprimée. Vous pouvez vous réinscrire à tout moment si vous changez d\'avis.',
      default_message: 'Nous confirmons votre inscription à ce webinaire. Au plaisir de vous y voir!',
      error_message: 'Une erreur est survenue lors du traitement de votre requête. Veuillez communiquer avec <a target="_blank" href="mailto:members@starlingminds.com?subject=Webinar%20registration%20issue">members@starlingminds.com</a> pour obtenir de l\'aide.',
      confirmation: {
        title: 'Supprimer l\'inscription?',
        description: 'Vous ne recevrez plus de communications à propos de ce webinaire.',
      },
    },
  },
  comments: {
    buttons: {
      post: 'Publier',
    },
    empty: {
      title: 'Il n\'y a pas encore de commentaire. Partagez vos pensées et écrivez un premier commentaire.',
    },
    author: '{name} (moi)',
    reply: { label: 'Ajoutez votre réponse' },
    comment: { label: 'Envoyer un commentaire avec la communauté' },
    comment_count: 'Commentaire | Commentaires',
    comment_card: {
      actions: {
        guideline: {
          label: 'Guide',
          icon: 'help_outline',
          showSameUser: true,
          showOtherUser: true,
          showModerator: true,
          showHidden: false,
        },
        hide: {
          label: 'Masquer',
          icon: 'visibility_off',
          showSameUser: false,
          showOtherUser: true,
          showModerator: true,
          showHidden: false,
        },
        unhide: {
          label: 'Démasquer',
          icon: 'visibility',
          showSameUser: false,
          showOtherUser: true,
          showModerator: true,
          showHidden: true,
        },
        block: {
          label: 'Bloquer',
          icon: 'block',
          showSameUser: false,
          showOtherUser: true,
          showModerator: false,
          showHidden: false,
        },
        delete: {
          label: 'Supprimer',
          icon: 'delete',
          showSameUser: true,
          showOtherUser: false,
          showModerator: false,
          showHidden: false,
        },
        edit: {
          label: 'Modifier',
          icon: 'edit',
          showSameUser: true,
          showOtherUser: false,
          showModerator: false,
          showHidden: false,
        },
        report: {
          label: 'Signaler',
          icon: 'report',
          showSameUser: false,
          showOtherUser: true,
          showModerator: false,
          showHidden: false,
        },
        reported: {
          label: 'Signalé',
          icon: 'check_circle',
          showSameUser: false,
          showOtherUser: true,
          showModerator: false,
          showHidden: false,
        },
        translate: {
          label: 'Traduire',
          icon: 'translate',
          showSameUser: false,
          showOtherUser: true,
          showModerator: true,
          showHidden: false,
        },
      },
      from: 'De',
      preview: 'prévisualiser cet écran',
      translated: 'Ce commentaire a été traduit automatiquement.',
      translated_cancel: 'Voir le commentaire original.',
      first_respond: 'Soyez la première personne à répondre',
      reply: 'réponse',
      replies: 'réponses',
      helpful: 'Utile',
      care: 'Solidaire',
      thanks: 'Merci',
      report: {
        title: 'Signaler un commentaire',
        subtitle: 'Merci de signaler ce commentaire. Veuillez s\'il vous plait nous indiquer la raison de ce signalement.',
        reasons: [
          'Contient un langage offensant, agressant ou abusif',
          'C\'est du spam ou hors sujet',
          'Montre un risque élevé de pensées autodestructrices ou suicidaires',
        ],
      },
      guideline: {
        title: 'Guide de la communauté',
        paragraph_first: 'Connectez-vous à la communauté pour faire part de votre expérience et lire les commentaires d’autres membres.',
        subtitle_first: '<strong>Nos règles de conduite</strong>',
        rules: [
          'Faire preuve de respect.',
          'Ne pas divulguer de données à caractère personnel.',
          'Soutenir les autres membres.',
        ],
        paragraph_second: 'Notre équipe de soutien surveille les messages pour veiller à ce que ces espaces demeurent des endroits sûrs pour tous. Toutefois, vous pouvez masquer, bloquer et signaler les commentaires d’autres membres, de sorte que vous décidez de ce que vous voulez voir.',
        subtitle_second: '<strong>Répondre à un commentaire</strong>',
        paragraph_third: 'Répondez et ajoutez des réactions aux commentaires des autres membres.',
        helpful: '<strong>Utile</strong> - Exprimez votre appréciation pour un commentaire pertinent.',
        care: '<strong>Solidaire</strong> - Exprimez votre soutien à un autre membre.',
        thanks: '<strong>Merci</strong> - Exprimez votre reconnaissance à un autre membre.',
        button_action: 'J\'ai compris!',
      },
      hide_confirmation: {
        title: 'Masquer la publication',
        subtitle: 'Vous ne verrez plus cette publication.',
      },
      block_confirmation: {
        title: 'Bloquer le membre',
        subtitle: 'Vous ne verrez plus de publications ni de réponses de ce membre.',
      },
    },
    sorts: [ 'Trier par plus récent', 'Trier par plus ancien', 'Trier par réactions' ],
    notifications: {
      reported: 'Commentaire signalé',
      updated: 'Commentaire mis à jour',
      deleted: 'Commentaire supprimé',
      helpful_marked: '"Utile" ajouté au commentaire',
      helpful_unmarked: '"Utile" retiré du commentaire',
      care_marked: '"Intérêt" ajouté au commentaire',
      care_unmarked: '"Intérêt" retiré du commentaire',
      thanks_marked: '"Merci" ajouté au commentaire',
      thanks_unmarked: '"Merci" retiré du commentaire',
      replied: 'Réponse ajoutée',
      commented: 'Commentaire ajouté',
    },
  },
  element_types: {
    video: {
      title: 'Video',
      icon: 'mdi-play-box-outline',
      color: '#5CCEFF',
    },
    workbook: {
      title: 'Workbook',
      icon: 'mdi-book-open-page-variant',
      responseLabel: 'Your Response',
      color: '#C288CB',
    },
    poll: {
      title: 'Poll',
      icon: 'mdi-poll-box-outline',
      color: '#75CB99',
    },
    decision: {
      title: 'Decision',
      icon: 'mdi-arrow-decision',
      color: '#FFA4A9',
      subtitle: 'Choose what you would like to do next',
    },
    submenu: {
      title: 'Submenu',
      icon: 'mdi-menu-open',
      color: '#0098E1',
      subtitle: 'Choose what you would like to do next',
    },
    mood: {
      title: 'Mood Tracker',
      icon: 'mdi-emoticon-confused',
      color: '#FFB92E',
    },
    html: {
      title: 'HTML',
      icon: 'mdi-note',
      color: '#6A89FF',
    },
    goal: {
      title: 'Goal Setter',
      icon: 'mdi-mountain',
      color: '#6CC5B9',
    },
    assessment: {
      title: 'Assessment',
      icon: 'mdi-head-cog',
      color: '#FF8884',
      instructions: 'Répondez à toutes les questions pour continuer.',
    },
    thoughts: {
      title: 'Thought Balancer',
      icon: 'mdi-weather-lightning-rainy',
      color: '#E1CCF1',
    },
    mcq: {
      title: 'MCQ',
      icon: 'mdi-format-list-bulleted',
      color: '#E1CCF1',
    },
    msq: {
      title: 'MSQ',
      icon: 'mdi-format-list-bulleted-square',
      color: '#F2DDA2',
    },
    slider: {
      title: 'Slider',
      icon: 'mdi-gauge',
      color: '#ff9800',
    },
    composition: {
      title: 'Composition',
      icon: 'mdi-animation-outline',
      color: '#7E8DC1',
    },
    next_step: {
      title: 'Next Step',
      icon: 'mdi-page-next',
      color: '#736010',
    },
    popup: {
      title: 'Popup',
      icon: 'mdi-launch',
      color: '#CABE70',
    },
    cu_scheduler: {
      title: 'Check-Up Scheduler',
      icon: 'mdi-calendar',
      color: '#AEDD2F',
    },
    journal: {
      title: 'Journal',
      icon: 'mdi-book-open-page-variant',
      responseLabel: 'Add yout thoughts',
      color: '#C288CB',
    },
    accordion: {
      title: 'Accordion',
      icon: 'mdi-chevron-down',
      color: '#B4BC54',
    },
    carousel: {
      title: 'Carousel',
      icon: 'mdi-view-carousel',
      color: '#47688C',
    },
    note: {
      title: 'Note',
      icon: 'mdi-book-open-page-variant',
      color: '#C288CB',
    },
  },
  assessment_history_chart: {
    title: 'Historique de l\'évaluation',
    chart: {
      label: {
        xAxis: 'Dates',
        xAxisVersion: 'final',
        yAxis: 'Pointage',
      },
      score: {
        label: {
          severe: 'Grave',
          moderate: 'Modérée',
          mild: 'Léger',
          border_line: 'Frontière',
          healthy_low: 'En santé : Moyenne faible',
          healthy_average: 'En santé : Moyenne',
          helthy_high: 'En santé : Moyenne élevée',
        },
      },
      assessment: {
        label: {
          gad7: 'ANXIÉTÉ',
          phq9: 'DÉPRESSION',
          stress: 'STRESS',
          energy: 'ÉNERGIE',
          'cd-risc2': 'RÉSILIENCE',
          ldq: 'LDQ',
        },
      },
    },
  },
  parts: {
    guide: {
      read_more: 'Lire la suite',
      read_less: 'Lire moins',
    },
    navigation: {
      button: 'S\'inscrire',
    },
    step_number: {
      title: 'Étape {step} de {of}',
    },
  },
};
