<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="51" height="51" viewBox="0 0 51 51" fill="none">
        <mask id="mask0_4600_23716" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="51" height="51">
            <rect x="0.210938" y="0.927734" width="50" height="50" fill="#D9D9D9"/>
        </mask>
        <g mask="url(#mask0_4600_23716)">
            <path d="M13.7526 29.0529H28.3359V26.9695H13.7526V29.0529ZM13.7526 22.8029H36.6693V20.7195H13.7526V22.8029ZM13.7526 16.5529H34.4656V16.5049C33.9556 16.2539 33.5116 15.9635 33.1339 15.6336C32.7557 15.3037 32.4118 14.9157 32.1021 14.4695H13.7526V16.5529ZM6.46094 42.755V10.5435C6.46094 9.58446 6.78212 8.78377 7.42448 8.14141C8.06684 7.49904 8.86753 7.17786 9.82656 7.17786H30.6276C30.5422 7.52509 30.4927 7.86571 30.4792 8.19974C30.466 8.53342 30.462 8.88724 30.4672 9.2612H9.82656C9.50573 9.2612 9.21181 9.3947 8.94479 9.66172C8.67778 9.92873 8.54427 10.2227 8.54427 10.5435V37.6867L11.9818 34.2612H40.5953C40.9161 34.2612 41.2101 34.1277 41.4771 33.8607C41.7441 33.5937 41.8776 33.2997 41.8776 32.9789V18.3076C42.2755 18.2141 42.6415 18.1067 42.9755 17.9852C43.3092 17.8636 43.6377 17.7067 43.9609 17.5143V32.9789C43.9609 33.9379 43.6398 34.7386 42.9974 35.381C42.355 36.0233 41.5543 36.3445 40.5953 36.3445H12.8714L6.46094 42.755ZM8.54427 10.5435V37.1456V9.2612V10.5435ZM39.7943 14.3893C38.3519 14.3893 37.1233 13.8819 36.1083 12.8669C35.0934 11.852 34.5859 10.6234 34.5859 9.18099C34.5859 7.73863 35.0934 6.50998 36.1083 5.49505C37.1233 4.48012 38.3519 3.97266 39.7943 3.97266C41.2366 3.97266 42.4653 4.48012 43.4802 5.49505C44.4951 6.50998 45.0026 7.73863 45.0026 9.18099C45.0026 10.6234 44.4951 11.852 43.4802 12.8669C42.4653 13.8819 41.2366 14.3893 39.7943 14.3893Z" fill="#2194DD"/>
        </g>
        <path d="M39.7949 15.5977C38.0588 15.5977 36.5831 14.99 35.3678 13.7747C34.1526 12.5595 33.5449 11.0838 33.5449 9.34766C33.5449 7.61155 34.1526 6.13585 35.3678 4.92057C36.5831 3.7053 38.0588 3.09766 39.7949 3.09766C41.531 3.09766 43.0067 3.7053 44.222 4.92057C45.4373 6.13585 46.0449 7.61155 46.0449 9.34766C46.0449 11.0838 45.4373 12.5595 44.222 13.7747C43.0067 14.99 41.531 15.5977 39.7949 15.5977Z" fill="#FF777E"/>
    </svg>
  </template>

<script>
export default {
  name: 'comment-new-reply-icon',
};
</script>

  <style scoped>
  .comment-new-reply-icon {
    stroke: currentColor;
    fill: currentColor;
  }
  </style>
