export default {
  header_title: 'Training',
  recommended: {
    title: 'Recommended:',
    subtitle: 'Based on your current Check-Up, here are the recommended modules for you:',
  },
  modules: {
    title: 'Modules',
    sessions: 'Training Session',
    button_explore: 'Explore',
    button_start_session: 'Start Session',
  },
  discover: {
    title: 'Discover:',
  },
  completed: {
    title: 'Completed:',
    subtitle: 'Revisit your completed modules to refresh your skills.',
  },
  first_checkup: {
    text: 'Complete a <strong>Check-Up</strong> to generate your recommendation.',
    button: 'Take a Check-Up',
  },
  buttons: {
    more: 'More',
    less: 'Less',
  },
  empty: {
    title: '',
    subtitle: '',
  },
  element: {
    reports: {
      download_button: 'Download Data',
    },
    popup: {
      tell_more_button: 'Tell me more',
      download_button: 'Download PDF',
    },
    goal: {
      title: 'Goal created',
      subtitle: 'Good work creating your goal! Let’s work on the next few days to complete it. <br><br> This is your goal card. You can find this in your goal setter history.',
    },
    mood: {
      title: 'Mood tracked',
      subtitle: 'This is your mood card! You can find this in your mood tracker history.',
    },
    thought: {
      title: 'Thought balanced',
      subtitle: 'Good work balancing your thought. It can be very hard to think in a different manner.<br><br>This is your thought card! You can find this in your thought balancer history.',
    },
    cu_scheduler: {
      label: 'Take my next Check-Up in',
      choices: {
        two_weeks: '2 weeks',
        four_weeks: '4 weeks',
        six_weeks: '6 weeks',
        eight_weeks: '8 weeks',
      },
    },
    webinar_registration: {
      register_button: 'Click to register',
      unregister_button: 'Registered!',
      success_message: 'Check your email for a confirmation.',
      join_now_button: 'Live, click to join!',
      removed_message: 'Registration removed. You can register again anytime if you change your mind.',
      default_message: 'You are registered for this webinar. See you there!',
      error_message: 'There was an error in processing your request. Please contact <a target="_blank" href="mailto:members@starlingminds.com?subject=Webinar%20registration%20issue">members@starlingminds.com</a> for assistance.',
      confirmation: {
        title: 'Delete registration?',
        description: 'You will no longer receive updates from this webinar.',
      },
    },
  },
  comments: {
    buttons: {
      post: 'Post',
    },
    empty: {
      title: 'There is no comment yet. Share your thoughts and write the first one.',
    },
    author: '{name} (me)',
    reply: { label: 'Add your reply' },
    comment: { label: 'Share a comment with the community' },
    comment_count: 'Comment | Comments',
    comment_card: {
      actions: {
        guideline: {
          label: 'Guidelines',
          icon: 'help_outline',
          showSameUser: true,
          showOtherUser: true,
          showModerator: true,
          showHidden: false,
        },
        hide: {
          label: 'Hide',
          icon: 'visibility_off',
          showSameUser: false,
          showOtherUser: true,
          showModerator: true,
          showHidden: false,
        },
        unhide: {
          label: 'Unhide',
          icon: 'visibility',
          showSameUser: false,
          showOtherUser: true,
          showModerator: true,
          showHidden: true,
        },
        block: {
          label: 'Block',
          icon: 'block',
          showSameUser: false,
          showOtherUser: true,
          showModerator: false,
          showHidden: false,
        },
        delete: {
          label: 'Delete',
          icon: 'delete',
          showSameUser: true,
          showOtherUser: false,
          showModerator: false,
          showHidden: false,
        },
        edit: {
          label: 'Edit',
          icon: 'edit',
          showSameUser: true,
          showOtherUser: false,
          showModerator: false,
          showHidden: false,
        },
        report: {
          label: 'Report',
          icon: 'report',
          showSameUser: false,
          showOtherUser: true,
          showModerator: false,
          showHidden: false,
        },
        reported: {
          label: 'Reported',
          icon: 'check_circle',
          showSameUser: false,
          showOtherUser: true,
          showModerator: false,
          showHidden: false,
        },
        translate: {
          label: 'Translate',
          icon: 'translate',
          showSameUser: false,
          showOtherUser: true,
          showModerator: true,
          showHidden: false,
        },
      },
      from: 'From',
      preview: 'preview this screen',
      translated: 'This post has been translated from its original language.',
      translated_cancel: 'Show post in its original language.',
      first_respond: 'Be the first to respond',
      reply: 'reply',
      replies: 'replies',
      helpful: 'helpful',
      care: 'care',
      thanks: 'thanks',
      report: {
        title: 'Report Comment',
        subtitle: 'Thank you for reporting this comment, please let us know the reason for reporting it.',
        reasons: [
          'Contains offensive language, harassment or abuse',
          'It\'s spam or is off-topic',
          'Indicates potential for self-harm or suicidal thoughts',
        ],
      },
      guideline: {
        title: 'Community Guide',
        paragraph_first: 'Connect with the community to share your experience and read comments from other members.',
        subtitle_first: '<strong>Our community rules</strong>',
        rules: [
          'Be respectful.',
          'Don\'t share identifying information.',
          'Be supportive.',
        ],
        paragraph_second: 'Our support team monitors posts to make sure this is a safe space for everyone. However, you can hide, block, and report member comments so you  decide what you see.',
        subtitle_second: '<strong>Respond to a comment</strong>',
        paragraph_third: 'Reply and add reactions to other member\'s comments. ',
        helpful: '<strong>Helpful</strong> - Show love for a comment that was insightful.',
        care: '<strong>Care</strong> - Show support for another member.',
        thanks: '<strong>Thanks</strong> - Show appreciation to another member.',
        button_action: 'Got it!',
      },
      hide_confirmation: {
        title: 'Hide post',
        subtitle: 'The contents of this post will be hidden for you.',
      },
      block_confirmation: {
        title: 'Block member',
        subtitle: 'You will no longer see any posts or replies from this member.',
      },
    },
    sorts: [ 'Sort by newest', 'Sort by oldest', 'Sort by reactions' ],
    notifications: {
      reported: 'Comment reported',
      updated: 'Comment updated',
      deleted: 'Comment deleted',
      helpful_marked: 'Comment liked',
      helpful_unmarked: 'Comment unliked',
      care_marked: 'Comment care marked',
      care_unmarked: 'Comment care unmarked',
      thanks_marked: 'Comment thanks marked',
      thanks_unmarked: 'Comment thanks unmarked',
      replied: 'Reply added',
      commented: 'Comment added',
    },
  },
  element_types: {
    video: {
      title: 'Video',
      icon: 'mdi-play-box-outline',
      color: '#5CCEFF',
    },
    workbook: {
      title: 'Workbook',
      icon: 'mdi-book-open-page-variant',
      responseLabel: 'Your Response',
      color: '#C288CB',
    },
    poll: {
      title: 'Poll',
      icon: 'mdi-poll-box-outline',
      color: '#75CB99',
    },
    decision: {
      title: 'Decision',
      icon: 'mdi-arrow-decision',
      color: '#FFA4A9',
      subtitle: 'Choose what you would like to do next',
    },
    submenu: {
      title: 'Submenu',
      icon: 'mdi-menu-open',
      color: '#0098E1',
      subtitle: 'Choose what you would like to do next',
    },
    mood: {
      title: 'Mood Tracker',
      icon: 'mdi-emoticon-confused',
      color: '#FFB92E',
    },
    html: {
      title: 'HTML',
      icon: 'mdi-note',
      color: '#6A89FF',
    },
    goal: {
      title: 'Goal Setter',
      icon: 'mdi-mountain',
      color: '#6CC5B9',
    },
    assessment: {
      title: 'Assessment',
      icon: 'mdi-head-cog',
      color: '#FF8884',
      instructions: 'Answer all questions to move forward.',
    },
    thoughts: {
      title: 'Thought Balancer',
      icon: 'mdi-weather-lightning-rainy',
      color: '#E1CCF1',
    },
    mcq: {
      title: 'MCQ',
      icon: 'mdi-format-list-bulleted',
      color: '#E1CCF1',
    },
    msq: {
      title: 'MSQ',
      icon: 'mdi-format-list-bulleted-square',
      color: '#F2DDA2',
    },
    slider: {
      title: 'Slider',
      icon: 'mdi-gauge',
      color: '#ff9800',
    },
    composition: {
      title: 'Composition',
      icon: 'mdi-animation-outline',
      color: '#7E8DC1',
    },
    next_step: {
      title: 'Next Step',
      icon: 'mdi-page-next',
      color: '#736010',
    },
    popup: {
      title: 'Popup',
      icon: 'mdi-launch',
      color: '#CABE70',
    },
    cu_scheduler: {
      title: 'Check-Up Scheduler',
      icon: 'mdi-calendar',
      color: '#AEDD2F',
    },
    journal: {
      title: 'Journal',
      icon: 'mdi-book-open-page-variant',
      responseLabel: 'Add yout thoughts',
      color: '#C288CB',
    },
    accordion: {
      title: 'Accordion',
      icon: 'mdi-chevron-down',
      color: '#B4BC54',
    },
    carousel: {
      title: 'Carousel',
      icon: 'mdi-view-carousel',
      color: '#47688C',
    },
    note: {
      title: 'Note',
      icon: 'mdi-book-open-page-variant',
      color: '#C288CB',
    },
  },
  assessment_history_chart: {
    title: 'Assessment History',
    chart: {
      label: {
        xAxis: 'Dates',
        xAxisVersion: 'final',
        yAxis: 'Score',
      },
      score: {
        label: {
          severe: 'Severe',
          moderate: 'Moderate',
          mild: 'Mild',
          border_line: 'Borderline',
          healthy_low: 'Healthy: Low Average',
          healthy_average: 'Healthy: Average',
          helthy_high: 'Healthy: High Average',
        },
      },
      assessment: {
        label: {
          gad7: 'ANXIETY',
          phq9: 'DEPRESSION',
          stress: 'STRESS',
          energy: 'ENERGY',
          'cd-risc2': 'RESILIENCE',
          ldq: 'LDQ',
        },
      },
    },
  },
  parts: {
    guide: {
      read_more: 'Read more',
      read_less: 'Read less',
    },
    navigation: {
      button: 'Register',
    },
    step_number: {
      title: 'Step {step} of {of}',
    },
  },
};
