export default {
  header_title: 'Mi perfil',
  navigation: [
    {
      id: 'account',
      label: 'Cuenta',
      path: 'profile-account',
    },
    {
      id: 'settings',
      label: 'Ajustes',
      path: 'profile-settings',
    },
  ],
  header: {
    fields: {
      name: {
        title: 'Nombre',
        question: '¿Cómo deberíamos llamarte?',
        label: 'Nombre',
      },
    },
  },
  account: {
    title: 'Perfil',
    joined: 'Se unió',
    fields: {
      codeName: {
        title: 'Seudónimo para la comunidad',
        question: 'Elegir un seudónimo para la comunidad',
        label: 'Seudónimo para la comunidad',
        hint: 'Haz clic en el botón de abajo para generar un nuevo seudónimo.',
        provider: {
          title: 'Generar',
        },
      },
      yearOfBirth: {
        title: 'Año de nacimiento',
        question: '¿Podemos preguntarte tu año de nacimiento?',
        label: 'Año de nacimiento',
      },
      profession: {
        title: 'Ocupación',
        question: '¿Podemos preguntarte tu ocupación?',
        label: 'Ocupación',
      },
      memberCode: {
        title: 'Identificación de miembro',
        question: '¿Podemos preguntarte tu identificación de miembro?',
        label: 'Identificación de miembro',
      },
      businessUnit: {
        title: 'Unidad de negocio',
        question: '¿Podemos preguntarte tu unidad de negocio?',
        label: 'Unidad de negocio',
      },
      gender: {
        title: 'Género',
        question: '¿Cómo te gustaría que se dirigiera a ti la gente?',
        label: 'Género',
        custom_label: 'Me describo como',
      },
      password: {
        title: 'Contraseña',
        question: 'Cambiar tu contraseña',
        current_password: 'Contraseña actual',
        new_password: 'Nueva contraseña',
        confirm_password: 'Confirmar contraseña',
      },
      email: {
        title: 'Correo electrónico',
      },
      organization: {
        title: 'Organización',
      },
    },
    delete_account: {
      title: 'Eliminar cuenta',
      description: 'Por favor, confirma la eliminación de la cuenta. Esta acción es irreversible y eliminará permanentemente todos tus datos asociados a esta cuenta.',
      confirmation: {
        label: 'Para confirmar, escriba <strong><i>eliminar cuenta</i></strong> en el campo de entrada de texto.',
        safe_word: 'eliminar cuenta',
      },
      button: 'Eliminar cuenta',
      confirm_button: 'Eliminar cuenta',
    },
  },
  community: {
    title: 'Configuración de la comunidad',
    tagline: 'Esto te representará en la comunidad.',
  },
  settings: {
    title: 'Parámetros globales',
    fields: {
      language: {
        title: 'Idioma',
        question: '¿Qué idioma utilizas?',
        label: 'Idioma',
      },
      enable: {
        title: 'Activar la traducción',
      },
      timezone: {
        title: 'Zona horaria',
        question: 'Actualizar la zona horaria predeterminada de tu cuenta',
        label: 'Zona horaria',
      },
    },
  },
  home_settings: {
    title: 'Configuración de inicio',
    tiles: {
      title: 'Azulejos',
      action: 'Visible',
      community_notification: 'Notificaciones comunitarias',
    },
  },
  communication: {
    title: 'Ajustes de notificaciones',
    email: 'Email',
    app: 'Apli',
    comments: {
      title: 'Comentarios',
      reactions: 'Reacciones',
      replies: 'Respuestas',
    },
    progress: {
      title: 'Progreso',
      session: 'Sesión',
      checkup: 'Revisión',
      goal_reminder: 'Recordatorios de objetivos',
      webinar_reminder: 'Recordatorios de seminarios web',
    },
    completion: {
      title: 'Completación',
      session: 'Sesión',
      exercise: 'Ejercicios',
    },
    product_updates: {
      updates: 'Actualizaciones del producto',
    },
    goal_reminder_modal: {
      title: 'Atención',
      description: 'Si desactivas los recordatorios de objetivos, se pausarán las notificaciones de todos los objetivos que hayas establecido.<br><br>Puedes desactivar los recordatorios de un objetivo específico en la <strong>Plantilla de objetivos</strong> en <strong>Herramientas</strong>.',
    },
    warning_push_disabled: {
      title: 'Atención.',
      description: 'Tu dispositivo no está configurado para recibir notificaciones automáticas. Puedes activar esta función en los ajustes de tu teléfono.',
    },
  },
  feedback_block: {
    title: 'Comentarios',
    greeting: '¿Cómo lo estamos haciendo?',
    buttons: {
      write: 'Enviar tus comentarios',
    },
  },
  misc: {
    no_disclosure: 'Prefiero no responder.',
    self_describe: 'Me describo como {value}',
    no_match: 'No se ha encontrado ninguna coincidencia.',
  },
  notifications: {
    updated: 'Perfil actualizado',
    password_changed: 'Contraseña cambiada',
    password_not_changed: 'Tu contraseña actual es incorrecta. Inténtalo de nuevo.',
  },
  feedback: {
    mailto: 'mailto:members@starlingminds.com?subject=Comentarios%20y%20preguntas',
  },
  webinars: {
    title: 'Configuración para los seminarios web',
    header_webinars: 'Seminarios web',
    header_registered: 'Registro',
    no_items: 'No hay próximos seminarios web. Activa la opción de actualizaciones de productos en los ajustes de notificación para mantenerte al día de los nuevos seminarios web.',
  },
};
