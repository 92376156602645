/* eslint-disable security/detect-object-injection */
import { Module } from 'vuex';
import { RootState } from '../../types';
import { CommentState, IComment, ICommentPage, ICommentStatus, ICommentTranslation } from './types';

const parseCommentTags = (comment: string) => {
  return { text: comment, usersList: [] };
};

export const defaultState: CommentState = {
  comments: [],
  commentsStatuses: [],
  commentsMarkedAsReadLater: [],
  totalComments: 0,
  guidelineViewed: false,
};

export const comments: Module<CommentState, RootState> = {
  namespaced: true,
  state: defaultState,
  mutations: {
    setComments(state, commentList: Array<IComment>) {
      state.comments = commentList.map(c => {
        if (!c.replies) c.replies = [];
        return c;
      });
    },
    setTotalComments(state, totalComments: number) {
      state.totalComments = totalComments;
    },
    setCommentsStatuses(state, commentsStatuses: Array<ICommentStatus>) {
      state.commentsStatuses = commentsStatuses;
    },
    deleteComment(state, index) {
      state.comments.splice(index, 1);
    },
    addComment(state, { index, deletedComment }) {
      state.comments.splice(index, 0, deletedComment);
    },
    setReplies(state, { commentId, replies, count = null }) {
      state.comments = state.comments.map(comment => {
        if (comment.id === commentId) {
          comment.replies = replies;
          if (count) comment.replyCount = count;
        }
        return comment;
      });
    },
    appendReplies(state, { commentId, replies, count = null }) {
      state.comments = state.comments.map(comment => {
        if (comment.id === commentId) {
          const filtered = replies.filter((reply: IComment) => !comment.replies.some(existing => existing.id === reply.id));
          comment.replies = [ ...comment.replies, ...filtered ];
          if (count) {
            comment.replyCount = count;
          } else {
            comment.replyCount = comment.replyCount + replies.length;
          }
        }
        return comment;
      });
    },
    deleteReply(state, { commentIndex, replyIndex }) {
      state.comments[commentIndex].replies.splice(replyIndex, 1);
      state.comments[commentIndex].replyCount = state.comments[commentIndex].replyCount - 1;
    },
    setGuidelineViewed(state, newValue) {
      state.guidelineViewed = newValue.communityGuidelineViewed;
    },
  },
  actions: {
    getCommentsStatusesByUser({ state, commit, dispatch }) {
      const callback = (res: ICommentStatus[]) => {
        commit('setCommentsStatuses', res);
        return res;
      };
      return dispatch('$apiRequest', [ `/comments/statuses`, callback ], { root: true });
    },
    getCommentsByUser({ state, commit, dispatch }, { page = 0, sort = 'id', dir = 'DESC' }) {
      const callback = (res: ICommentPage) => {
        commit('setComments', res.content);
        commit('setTotalComments', res.totalElements);
        return res;
      };
      return dispatch('$apiRequest', [ `/comments?page=${page}&size=5&sort=${sort}%2C${dir}`, callback ], { root: true });
    },
    getCommentsByUen({ state, commit, dispatch }, { uen, page = 0, sort = 'id', dir = 'DESC' }) {
      const callback = (res: ICommentPage) => {
        commit('setComments', res.content);
        commit('setTotalComments', res.totalElements);
        return res;
      };
      return dispatch('$apiRequest', [ `/comments/${uen}?page=${page}&size=5&sort=${sort}%2C${dir}`, callback ], { root: true });
    },
    postComment({ dispatch, commit }, commentData: IComment) {
      commentData = { ...commentData, ...parseCommentTags(commentData.text) };
      const callback = (res: IComment) => {
        return res;
      };
      commentData.report = { title: '', isReported: false };
      return dispatch('$apiRequest', [ `/comments`, callback, 'post', commentData ], { root: true });
    },
    getReplies({ commit, dispatch }, { id }) {
      const callback = (res: ICommentPage) => {
        commit('setReplies', { commentId: id, replies: res.content, count: res.totalElements });
        return res;
      };
      return dispatch('$apiRequest', [ `/comments/${id}/replies`, callback ], { root: true });
    },
    getLatestReply({ commit, dispatch }, { id }) {
      const callback = (res: ICommentPage) => {
        commit('setReplies', { commentId: id, replies: res.content, count: res.totalElements });
        return res;
      };
      return dispatch('$apiRequest', [ `/comments/${id}/latest_reply`, callback ], { root: true });
    },
    getUnreadReplies({ commit, dispatch }, { id }) {
      const callback = (res: ICommentPage) => {
        commit('setReplies', { commentId: id, replies: res.content });
        return res;
      };
      return dispatch('$apiRequest', [ `/comments/${id}/unread_replies`, callback ], { root: true });
    },
    translateComment({ commit, dispatch }, id: number) {
      const callback = (res: ICommentTranslation) => {
        return res;
      };
      return dispatch('$apiRequest', [ `/comments/${id}/_translate`, callback, 'post' ], { root: true });
    },
    markCommentAsRead({ commit, dispatch }, id: number) {
      const callback = (res: any) => {
        return dispatch('getCommentsStatusesByUser');
      };
      return dispatch('$apiRequest', [ `/comments/${id}/_read`, callback, 'post' ], { root: true });
    },
    markCommentAsReadLater({ commit, dispatch, state }, id: number) {
      if (state.commentsMarkedAsReadLater.indexOf(id) === -1) {
        state.commentsMarkedAsReadLater.push(id);
      }
    },
    processCommentsMarkedAsReadLater({ dispatch, state }) {
      const callback = (res: any) => {
        state.commentsMarkedAsReadLater = [];
        return dispatch('getCommentsStatusesByUser');
      };
      return dispatch('$apiRequest', [ `/comments/_read`, callback, 'post', state.commentsMarkedAsReadLater ], { root: true });
    },
    markCommentAsUnread({ commit, dispatch }, id: number) {
      const callback = (res: any) => {
        return dispatch('getCommentsStatusesByUser');
      };
      return dispatch('$apiRequest', [ `/comments/${id}/_unread`, callback, 'post' ], { root: true });
    },
    markCommentAsHidden({ commit, dispatch }, id: number) {
      const callback = (res: any) => {
        return dispatch('getCommentsStatusesByUser');
      };
      return dispatch('$apiRequest', [ `/comments/${id}/_hide`, callback, 'post' ], { root: true });
    },
    markCommentAsUnhidden({ commit, dispatch }, id: number) {
      const callback = (res: any) => {
        return dispatch('getCommentsStatusesByUser');
      };
      return dispatch('$apiRequest', [ `/comments/${id}/_unhide`, callback, 'post' ], { root: true });
    },
    markCommentHomeRead({ state, commit, dispatch }) {
      const replyIds = state.commentsStatuses.map(status => status.replyId);
      const callback = () => {};
      return dispatch('$apiRequest', [ `/comments/_home_read`, callback, 'post', replyIds ], { root: true });
    },
    blockCommentAuthor({ commit, dispatch }, id: number) {
      const callback = () => {};
      return dispatch('$apiRequest', [ `/comments/${id}/_block`, callback, 'post' ], { root: true });
    },
    postReply({ commit, dispatch }, replyData: IComment) {
      replyData = { ...replyData, ...parseCommentTags(replyData.text) };
      const callback = (res: IComment) => {
        commit('appendReplies', { commentId: res.parentId, replies: [ res ] });
        return res;
      };
      replyData.report = { title: '', isReported: false };
      return dispatch('$apiRequest', [ `/comments/_reply`, callback, 'post', replyData ], { root: true });
    },
    editComment({ commit, state, getters, dispatch, rootGetters }, commentData: IComment) {
      commentData = { ...commentData, ...parseCommentTags(commentData.text) };
      const comment = { ...getters.commentById(commentData.id), ...commentData };
      const callback = (res: IComment) => {
        return res;
      };
      return dispatch('$apiRequest', [ `/comments`, callback, 'put', comment ], { root: true });
    },
    reportComment({ commit, state, getters, dispatch, rootGetters }, commentData: IComment) {
      commentData = { ...commentData, ...parseCommentTags(commentData.text) };
      const comment = { ...getters.commentById(commentData.id), ...commentData };
      const callback = (res: IComment) => {
        return res;
      };
      return dispatch('$apiRequest', [ `/comments/_report`, callback, 'post', comment ], { root: true });
    },
    toggleHelpful({ commit, getters, dispatch }, { commentId, parentId = null }) {
      let comment: IComment;
      if (parentId) {
        comment = getters.commentById(parentId).replies.find((reply: IComment) => reply.id === commentId);
      } else {
        comment = getters.commentById(commentId);
      }
      const callback = (res: IComment) => {
        comment.isHelpful = res.isHelpful;
        comment.helpfulCount = res.helpfulCount;
        return res;
      };
      return dispatch('$apiRequest', [ `/comments/${commentId}/_helpful`, callback, 'post' ], { root: true });
    },
    toggleCare({ commit, getters, dispatch }, { commentId, parentId = null }) {
      let comment: IComment;
      if (parentId) {
        comment = getters.commentById(parentId).replies.find((reply: IComment) => reply.id === commentId);
      } else {
        comment = getters.commentById(commentId);
      }
      const callback = (res: IComment) => {
        comment.isCare = res.isCare;
        comment.careCount = res.careCount;
        return res;
      };
      return dispatch('$apiRequest', [ `/comments/${commentId}/_care`, callback, 'post' ], { root: true });
    },
    toggleThanks({ commit, getters, dispatch }, { commentId, parentId = null }) {
      let comment: IComment;
      if (parentId) {
        comment = getters.commentById(parentId).replies.find((reply: IComment) => reply.id === commentId);
      } else {
        comment = getters.commentById(commentId);
      }
      const callback = (res: IComment) => {
        comment.isThanks = res.isThanks;
        comment.thanksCount = res.thanksCount;
        return res;
      };
      return dispatch('$apiRequest', [ `/comments/${commentId}/_thanks`, callback, 'post' ], { root: true });
    },
    deleteComment({ state, commit, getters, dispatch }, { comment, page = 0 }) {
      const deletedComment = comment;
      if (deletedComment) {
        let callback = () => {};
        if (deletedComment.parentId) {
          const index = getters.commentIndexById(deletedComment.parentId ? deletedComment.parentId : deletedComment.id);
          const replyIndex = state.comments[index].replies.findIndex(item => item.id === deletedComment.id);
          callback = () => {
            if (deletedComment.parentId) {
              commit('deleteReply', { commentIndex: index, replyIndex });
            }
          };
        }

        return dispatch('$apiRequest', [ `/comments/${comment.id}`, callback, 'delete' ], { root: true });
      }
      return deletedComment;
    },
    markGuidelineViewed({ state, commit, dispatch }) {
      const callback = (res: Object) => {
        commit('setGuidelineViewed', res);
        return res;
      };
      return dispatch('$apiRequest', [ `/comments/_communityGuidelineViewed`, callback, 'post' ], { root: true });
    },
    fetchGuidelineViewed({ state, commit, dispatch }) {
      const callback = (res: Object) => {
        commit('setGuidelineViewed', res);
        return res;
      };
      return dispatch('$apiRequest', [ `/comments/_communityGuidelineViewed`, callback ], { root: true });
    },
  },
  getters: {
    getComments: state => {
      return state.comments;
    },
    getCommentsStatuses: state => {
      return state.commentsStatuses;
    },
    getTotalComments: state => {
      return state.totalComments;
    },
    commentIndexById: state => (id: number) => {
      if (state.comments.length) {
        return state.comments.findIndex(comment => comment.id === id);
      }
      return false;
    },
    commentById: state => (id: number) => {
      if (state.comments.length) {
        return state.comments.find(comment => comment.id === id);
      }
      return false;
    },
    getGuidelineViewed: state => {
      return state.guidelineViewed;
    },
  },
};
